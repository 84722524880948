import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import componentFactory from '../utils/componentFactory';

const PageTemplate = ({ pageContext }) => {
    const {
        data: {
            attributes: { Contenu },
        },
    } = pageContext;

    const components = Contenu.map((element) =>
        componentFactory(element, pageContext),
    );

    return (
        <>
            <Seo
                description={pageContext.data?.attributes.metaDescription}
                title={pageContext.data?.attributes.titre}
            ></Seo>
            <Layout pageContext={pageContext}>{components}</Layout>
        </>
    );
};

export default PageTemplate;
